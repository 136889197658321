var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Row', {
    staticClass: "expand-row"
  }, [_c('Col', {
    attrs: {
      "span": "6"
    }
  }, [_c('span', [_vm._v("注册邮箱:")]), _c('span', [_vm._v(_vm._s(_vm.row.email))])]), _c('Col', {
    attrs: {
      "span": "6"
    }
  }, [_c('span', [_vm._v("生日:")]), _c('span', [_vm._v(_vm._s(_vm.row.birth))])]), _c('Col', {
    attrs: {
      "span": "6"
    }
  }, [_c('span', [_vm._v("手机号:")]), _c('span', [_vm._v(_vm._s(_vm.row.mobile))])]), _c('Col', {
    attrs: {
      "span": "6"
    }
  }, [_c('span', [_vm._v("个人网页:")]), _c('a', {
    attrs: {
      "href": _vm.row.website,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.row.website))])])], 1), _c('Row', [_c('Col', {
    attrs: {
      "span": "6"
    }
  }, [_c('span', [_vm._v("GitHub:")]), _c('a', {
    attrs: {
      "href": _vm.row.github,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.row.github))])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };